import "./App.css";
import { Tree, TreeNode } from "react-organizational-chart";
import React from "react";
import { FaCloudflare } from "react-icons/fa";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import { BsGlobe } from "react-icons/bs";
import {
  ChakraProvider,
  Box,
  Text,
  Heading,
  Flex,
  Spacer,
  Link,
  Icon,
} from "@chakra-ui/react";

function NodeComponentEmployee({ title, office, skills }) {
  return (
    <Box
      p={2}
      textAlign="left"
      // rounded={"lg"}
      borderRadius="8px"
      border="3px solid #e5883d"
      w="100px">
      <Text fontSize="sm" fontWeight={"bold"} my={2}>
        {title}
      </Text>
      <Text fontSize="sm" my={2}>
        {office}
      </Text>
    </Box>
  );
}

function NodeComponentManager({ title, office }) {
  return (
    <Box
      ounded={"lg"}
      border="3px solid #e5883d"
      my={10}
      alignItems="center"
      alignContent="center"
      p={2}>
      <Heading size="xl">{title}</Heading>
      <Text>{office}</Text>
    </Box>
  );
}
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tree: null,
      downloadingChart: false,
      config: {},
      highlightPostNumbers: [1],
    };
  }

  // const [downloadedChart, SetDownloadedChart] = useState(false);
  // const [data, SetLoadedData] = useState(false);

  // const handleLoadConfig = () => {
  //   return data;
  // };

  async componentDidMount() {
    const data = await fetch(
      "https://assesmentbackend.daggy.workers.dev/organization-chart"
    );
    const json = await data.json();
    const raw_data = json.organization.departments;
    var id = 1;
    const children_arr = [];
    raw_data.forEach((element) => {
      id++;
      const dept_name = element.name;
      const manager_id = id;
      const manager_name = element.managerName;
      const non_manager_arr = [];
      var manager_itm = null;
      const manager_reports = element.employees.length - 1;
      element.employees.forEach((subelement) => {
        if (!subelement.isManager) {
          id++;
          non_manager_arr.push({
            id: id,
            person: {
              id: id,
              department: dept_name,
              name: subelement.name,
              office: subelement.office,
              title: "employee",
              skills: subelement.skills,
              totalReports: 0,
            },
            hasChild: false,
            hasParent: true,
            isHighlight: true,
            children: [],
          });
        } else {
          manager_itm = {
            id: manager_id,
            person: {
              id: manager_id,
              department: dept_name,
              office: subelement.office,
              name: manager_name,
              title: "Manager",
              skills: subelement.skills,
              totalReports: manager_reports,
            },
            hasChild: true,
            hasParent: true,
            isHighlight: true,
            children: [],
          };
        }
      });

      manager_itm.children = non_manager_arr;
      children_arr.push(manager_itm);
    });

    var root_node = {
      id: 1,
      person: {
        id: 1,
        department: "",
        name: "mysterious CEO",
        title: "CEO",
        totalReports: raw_data.length,
      },
      hasChild: true,
      hasParent: false,
      isHighlight: true,
      children: children_arr,
    };

    this.setState({ tree: root_node });
  }

  render() {
    return (
      <ChakraProvider>
        <div className="App">
          <Flex>
            <Box m={5} textAlign="left">
              <Heading textDecoration={"underline"} my={2} size="2xl">
                Organization Chart
              </Heading>
              <Flex alignItems="center">
                Powered by <Icon ml={1} size={20} as={FaCloudflare} />
              </Flex>

              <Text my={2}>
                Simple Fontend UI for Cloudflare General Assesment{" "}
              </Text>
              <Text my={2}>
                Scrolable Charts for Each Department in the Organization
              </Text>
            </Box>
            <Spacer />
            <Box>
              <Flex p={5} direction="column" textAlign="right">
                <Text>Made by Arnav Jindal</Text>
                <Link color="blue" href="https://github.com/daggy1234">
                  <Icon mr={1} as={AiFillGithub} />
                  Github
                </Link>
                <Link color="blue" href="https://arnavjindal.xyz">
                  <Icon mr={1} as={BsGlobe} />
                  Website
                </Link>
                <Link color="blue" href="https://linkedin.com/in/arnavjindal">
                  <Icon mr={1} as={AiFillLinkedin} />
                  Linkedin
                </Link>
              </Flex>
            </Box>
          </Flex>
          <header className="App-header">
            {this.state.tree ? (
              <div>
                {this.state.tree.children.map((elm) => (
                  <Box my={10}>
                    <Heading ml={4} textAlign="left">
                      {elm.person.department}
                    </Heading>
                    <Box overflowX={"scroll"}>
                      <Tree
                        lineWidth={"10px"}
                        lineColor={"#e5883d"}
                        lineBorderRadius={"10px"}
                        label={
                          <NodeComponentManager
                            title={elm.person.name}
                            office={elm.person.office}
                          />
                        }>
                        {elm.children.map((elm) => (
                          <TreeNode
                            label={
                              <NodeComponentEmployee
                                office={elm.person.office}
                                skills={elm.person.skills}
                                title={elm.person.name}
                              />
                            }
                          />
                        ))}
                      </Tree>
                    </Box>
                  </Box>
                ))}
              </div>
            ) : (
              // JSON.stringify(this.state.tree)
              <p>No Data yet</p>
            )}
          </header>
        </div>
      </ChakraProvider>
    );
  }
}
